import styled from '@emotion/styled'
import { Button } from 'app/components/Button'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  identifier?: string
  languageCode: string
  languagePrefix: string | null
}

export const IBEButton = memo(function IBEButton({
  languageCode,
  languagePrefix,
  identifier,
}: Props) {
  if (identifier !== 'Reservation System') {
    return null
  }

  return (
    <Container>
      <Button
        label={useVocabularyData('view-the-demo', languageCode)}
        target="_blank"
        URL={`https://www.simplebooking.it/ibe2/hotel/2973?lang=${
          languagePrefix ? languagePrefix.toLocaleUpperCase() : 'EN'
        }&cur=EUR`}
        variant="primary"
      />
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  background: black;
  padding: 3rem 2rem;

  a {
    width: 100%;
    max-width: 1320px;
    margin: 0 auto;
    text-align: center;
  }
`
