import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useEffect, useRef, useState } from 'react'

const AUTOPLAY_TIMER = 5000

export interface Props {
  logos: ImageProps[]
}
export const LogosSwiper = memo(function LogosSwiper({ logos }: Props) {
  if (logos.length === 0) {
    return null
  }

  const [pause, setPause] = useState(false)
  const timer = useRef<any>()

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    loop: true,
    slides: {
      perView: 5,
      spacing: 30,
    },
    breakpoints: {
      '(max-width: 767px)': {
        slides: {
          perView: 4,
        },
      },
      '(max-width: 579px)': {
        slides: {
          perView: 3,
        },
      },
      '(max-width: 419px)': {
        slides: {
          perView: 2,
          spacing: 20,
        },
      },
    },
  })

  useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [pause, slider, sliderRef])

  return (
    <Container>
      <Slider className="keen-slider" ref={sliderRef}>
        {logos.map((item, index) => (
          <Slide className="keen-slider__slide" key={index}>
            <Image {...item} />
          </Slide>
        ))}
      </Slider>
    </Container>
  )
})

const Container = styled.section`
  width: 100%;
  padding: 2rem;
  position: relative;
  text-align: center;
`

const Slider = styled.div`
  max-width: 1400px;
  margin: 2rem auto 0 auto;
  position: relative;
  overflow: hidden;
`

const Slide = styled.div`
  img {
    width: auto;
    height: 2.5rem;
  }
`
