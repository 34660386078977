import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  videoID?: string
}

export const VideoStripe = memo(function VideoStripe({ videoID }: Props) {
  if (!videoID) {
    return null
  }

  const videoURL = 'https://www.youtube.com/embed/' + videoID

  return (
    <Container>
      <Wrapper>
        <iframe
          src={videoURL}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  width: 100%;
  background-color: black;
  margin: 0 auto;
  padding: 4rem 0;
  iframe {
    border: 0;
    width: 100%;
    height: 415px;
  }
`

const Wrapper = styled.div`
  max-width: 1400px;
  margin: auto;
`
